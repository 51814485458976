import React from "react";

import { PureComponent, transformImageUrl } from "@reco-m/core";

export namespace Banner {
    export interface IProps extends PureComponent.IProps {
        bimg?: any;
        cimg?: any;
        cimgShow?: boolean;
        text?: any;
        lineHeight?: string;
        extraClass?: string;
    }

    export interface IState extends PureComponent.IState {
        bimg?: string;
        cimg?: string;
        cimgShow?: boolean;
        text?: any;
        lineHeight?: string;
    }

    export class Component<P extends IProps, S extends IState = IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            cimgShow: true,
        };

        render(): React.ReactNode {
            const { bimg, cimg, cimgShow, text, lineHeight, extraClass = "" } = this.props;

            return (
                <div
                    className={"banner-box " + extraClass}
                    style={{
                        background: `url(${bimg}) no-repeat`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    {cimgShow === true ? <img src={transformImageUrl("" + cimg, 100, 100)} alt="" /> : ""}
                    {text && (
                        <div className="banner-text" style={{ lineHeight: "" + lineHeight }}>
                            {text}
                        </div>
                    )}
                </div>
            );
        }
    }
}
