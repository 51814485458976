import React from "react";
import { template } from "@reco-m/core";
import { ViewComponent } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";
import { Row, Col } from "antd";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, footerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;

        render() {
            return (
                <div className="footer">
                    <div className="footer-content">
                        <div className="container">
                            <Row gutter={160}>
                                <Col xs={24} sm={24} md={10} className="text-right">
                                    <div className="footer-logo text-center">
                                        <img src="assets/images/yunqi/footer-code.png" width="128px" height="128px" />
                                        <div className="size-12 mt5">扫一扫关注我们</div>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={14} className="border-left-1px">
                                    <div className="contact-info">
                                        <div>
                                            <i className="icon iconbuilding size-14 mr5" />
                                            瑞谷云企
                                        </div>
                                        <div className="mt6">
                                            <i className="icon iconadds size-14 mr5" />
                                            上海市浦东新区秋月路26号张江矽岸国际7号楼
                                        </div>
                                        <div className="mt6">
                                            <i className="icon iconphone1 size-14 mr5" />
                                            <a href="tel:400-026-9898">400-026-9898</a>
                                        </div>
                                        <div className="mt6">
                                            <i className="icon iconemail mr5" />
                                            <a href="mailto:ruiguyunqi@serpark.cn">ruiguyunqi@serpark.cn</a>
                                        </div>
                                        <div className="mt15 size-12">
                                            版权所有© 瑞谷云企
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="footer-desc">
                        <div className="container">
                            <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2024100058号-1 </a>
                            <span className="mh15">|</span>
                            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502402212" target="_blank"><img src={"assets/images/bean.png"} width="14px" className="mr5" />沪公网安备31011502402212号</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
