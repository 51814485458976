import { router } from "dva";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";

import { loadLazyModule } from "@reco-w/core-ui";

function RouterConfig({ history }: any) {
    return (
        <ConfigProvider locale={zhCN}>
            <router.Router history={history}>
                <router.Switch>
                    {/*{Recognition()}*/}
                    <router.Route exact path="/home" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/home-home"))} />;
                    <router.Route path="/operating" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/merchants-operating"))} />
                    <router.Route path="/entrust" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/merchants-entrust"))} />
                    <router.Route path="/promote" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/merchants-promote"))} />
                    <router.Route path="/economics" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/merchants-economics"))} />
                    <router.Route path="/selection" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/selection-selection"))} />
                    <router.Route path="/zoology" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/zoology-zoology"))} />
                    <router.Route path="/information" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/information-information"))} />
                    <router.Route path="/aboutus" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/aboutus-aboutus"))} />
                    <router.Route path="/financing" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/financing-financing"))} />
                    <router.Route path="/classiccase" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/classiccase-classiccase"))} />
                    <router.Route path="/scientific" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/scientific-scientific"))} />
                    <router.Route path="/institute" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/institute-institute"))} />
                    <router.Route path="/aliyun" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/aliyun-e"))} />

                    <router.Route path="/contracts" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/contracts-contracts"))} />
                    <router.Route path="/estate" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/estate-estate"))} />
                    <router.Route path="/yunqi" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/yunqi-yunqi"))} />
                    <router.Route path="/templateone" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/templateone-templateone"))} />
                    <router.Route path="/templatetwo" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/templatetwo-templatetwo"))} />
                    <router.Route path="/backstageform" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/backstageform-backstageform"))} />

                    <router.Redirect to={`/home`} />
                </router.Switch>
            </router.Router>
        </ConfigProvider>
    );
}
// function Recognition() {
//     if (
//         navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
//     ) {
//         return <router.Route exact path="/home" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/mobile-home"))} />;
//     } else {
//         return <router.Route exact path="/home" component={loadLazyModule(() => import(/* webpackChunkName: "home" */ "@reco-w/home-home"))} />;
//     }
// }

export default RouterConfig;
