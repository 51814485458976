import React from "react";
import { Modal } from "antd";

import { PureComponent, transformAssetsUrl } from "@reco-m/core";

export namespace ImageAuto {
    export interface IProps extends PureComponent.IProps {
        src: any;
        height?: any;
        width?: any;
        radius?: any;
        compress?: any;
        canClick?: boolean;
        cutWidth?: any;
        cutHeight?: any;
        cropWidth?: any;
        cropHeight?: any;
    }

    export interface IState extends PureComponent.IState {
        src?: string;
        srcImg?: string;
        open?: boolean;
    }

    export class Component<P extends ImageAuto.IProps = ImageAuto.IProps, S extends ImageAuto.IState = ImageAuto.IState> extends PureComponent.Base<P, S> {
        static defaultProps: any = {
            compress: false,
            src: "./assets/images/image_loading.gif",
        };
    
        protected getInitState(_nextProps: Readonly<P>): Readonly<S> {
            return { srcImg: "./assets/images/image_loading.gif", open: false } as S;
        }
    
        handleImageErrored() {
            this.setState({ srcImg: "./assets/images/nopic.jpg", open: false });
        }

        componentReceiveProps() {
            this.setState({ src: "" });
        }
    
        loadFn() {
            const { src: target, cutWidth, cutHeight, cropWidth, cropHeight } = this.props;
            let src = (this.state && this.state.src) || target;
                if (cutWidth || cropWidth || cutHeight || cropHeight) {
                    const v=2;
                    src = `${src}?`
                    cutWidth && (src = `${src}width=${cutWidth*v}&`);
                    cutHeight && (src = `${src}height=${cutHeight *v}&`);
                    cropWidth && (src = `${src}cropWidth=${cropWidth*v}&`);
                    cropHeight && (src = `${src}cropHeight=${cropHeight *v}`);
                }
            this.setState({ srcImg: src });
        }
    
        /** 图片点击 */
        onImageClick() {
            this.setState({ open: true });
        }
    
        /** 图片取消 */
        onCancel() {
            this.setState({ open: false });
        }
    
        /** 图片显示模态框 */
        renderModalImage(): React.ReactNode {
            const { width, height, src: target, compress } = this.props,
                src = (this.state && this.state.src) || (compress ? `${target}?w=${width || height}&h=${height || width}` : target);
    
            const { open } = this.state;
    
            return (
                <Modal centered wrapClassName={"images-modal"} visible={open} footer={null} onCancel={() => this.onCancel()}>
                    <img style={{ width: "100%" }} src={transformAssetsUrl(src)} />
                </Modal>
            );
        }
    
        render(): React.ReactNode {
            const { src: target, className, canClick, cutWidth, cutHeight, cropWidth, cropHeight } = this.props;
                // src = (this.state && this.state.src) || (compress ? `${target}?w=${width |let src = (this.state && this.state.src) || target;
                let src = (this.state && this.state.src) || target;
                if (cutWidth || cropWidth || cutHeight || cropHeight) {
                    const v=2;
                    src = `${src}?`
                    cutWidth && (src = `${src}width=${cutWidth*v}&`);
                    cutHeight && (src = `${src}height=${cutHeight *v}&`);
                    cropWidth && (src = `${src}cropWidth=${cropWidth*v}&`);
                    cropHeight && (src = `${src}cropHeight=${cropHeight *v}`);
                }
    
            return (
                <>
                    <div
                        ref="height"
                        onClick={() => (canClick ? this.onImageClick() : null)}
                        className={className ? className + " images-auto animated fadeIn" : "images-auto animated fadeIn"}
                        style={{
                            width: this.props.width,
                            height: "" + this.props.height,
                            borderRadius: this.props.radius,
                            backgroundImage: `url(${transformAssetsUrl(this.state.srcImg)})`,
                        }}
                    >
                        <img
                            src={transformAssetsUrl(src)}
                            onError={this.handleImageErrored.bind(this)}
                            onLoad={this.loadFn.bind(this)}
                            style={{ width: "" + this.props.width, height: "" + this.props.height }}
                            alt=""
                        />
                        {this.props.children}
                    </div>
                    {this.renderModalImage()}
                </>
            );
        }
    }
    
}

