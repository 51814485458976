import { EffectsMapObject } from "dva";
import { ReducersMapObject } from "redux";
import { freeze } from "immer";
import { CoreEffects, CoreState, CoreReducers, getLocalStorage } from "@reco-m/core";
import { app } from "@reco-w/core-ui";

import { Namespaces } from "./common";

export namespace headerModel {
    export const namespace = Namespaces.header;


    export const state: any = freeze(
        {
            ...CoreState,
            dataSource: null,
            currentUser: null,
            parkNames: null,
            radioSelect: getLocalStorage("parkId") ? getLocalStorage("parkId") : "",
            parkId: getLocalStorage("parkId") ? getLocalStorage("parkId") : "",
            parkName: getLocalStorage("parkName") ? getLocalStorage("parkName") : "",
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        init() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
    };
}
app.model(headerModel);
